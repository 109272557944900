<template>
  <v-simple-table fixed-header
                  dense
                  mobile-breakpoint="0"
                  style="height: 100%;"
                  class="table-with-borders"
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center text-subtitle-1">{{$localize('nomer_p_p')}}</th>
        <th class="text-center text-subtitle-1">{{$localize('indicators')}}</th>
        <th class="text-center text-subtitle-1">{{$localize('criteria_signs_assessment_of_group_and_intragroup_indicators')}}</th>
        <th class="text-center text-subtitle-1">{{ $localize('eights') }}</th>
        <th class="text-center text-subtitle-1">{{ $localize('value_of_indicators') }}</th>
        <th class="text-center text-subtitle-1" colspan="2">{{ $localize('risk_assessment_class') }}</th>
        <th class="text-center text-subtitle-1">{{$localize('eighted_score')}}</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="( item, rowIdx) in items">
        <tr :key="rowIdx" class="group-name">
          <td>{{ rowIdx + 1 }}</td>
          <td>
            {{item.key}}
          </td>
          <td>
            {{item.title}}
          </td>
          <td>
            {{item.value}}
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{  getGroupSum(item) }}</td>
        </tr>
        <template v-if="item.items"></template>
        <template v-for="(childItem, childRowIdx) in item.items">
          <tr :key="'c' + rowIdx + '' +childRowIdx">
            <td style="width: 1%"></td>
            <td style="width: 5%">
              {{childItem.key}}
            </td>
            <td style="width: 15%">
              {{childItem.title}}
            </td>
            <td style="width: 5%">
              {{childItem.value}}
            </td>
            <td style="width: 30%">
              <v-autocomplete v-model="childItem.ratingUid"
                              :items="childItem.ratings"
                              item-value="uid"
                              item-text="title"
                              :rules="[rules.required]"
                              outlined
                              clearable
                              dense
                              class="mt-5"
                              @input="multiplication(childItem)"
              ></v-autocomplete>
            </td>
            <td style="width: 25%">
              {{ getRatingTitle(childItem.ratings, childItem.ratingUid) }}
            </td>
            <td style="width: 1%">{{childItem.risk}}</td>
            <td>{{childItem.grade}}</td>
          </tr>
        </template>
      </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default{
  name: "Rating",
  props: ['items'],
  data() {
    return {
      rules: {
        required: value => !!value || this.$localize('mandatory_field')
      },
    }
  },

  methods: {
    getRatingTitle(items, uid){
      if(!uid || !items) return ''
      const dictValue = items.find(el => {return el.uid === uid})
      return dictValue.value
    },

    multiplication(item){
      if(!item && !item.ratingUid) return ''
      if(item.ratingUid === null) return ''
      item.risk = item.ratings.find(el => item.ratingUid === el.uid).risk
      item.grade = (item.value * item.risk).toFixed(3)
    },

    getGroupSum(item){
      if(!item && !item.items) return ''
      let sum = 0;
      let isFilled = true
      item.items.forEach(el => {
        if(!el.grade || !el.risk || (!el.ratingUid === null || el.ratingUid === null)) {
          el.grade = undefined
          isFilled = false
        }
      })
      if(!isFilled) return ''
      item.items.forEach(el => {
        sum += Number(el.grade)
      })
      sum *= item.value
      item.totalGrade = sum.toFixed(3)
      return sum.toFixed(3)
    },
  }
}
</script>
