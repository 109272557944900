<template>
  <v-card class="view-wrapper">
    <v-toolbar dense class="toolbar-with-tabs elevation-0">
      <v-tabs
          ref="tabs"
          v-model="tab"
          style="position:sticky; top: 0; z-index: 1; width: auto"
          show-arrows
          class="mt-0 pt-0 mb-2"
          color="success"
      >
        <v-tab
            v-for="item in tabItems"
            :key="item.index"
        >
          {{ $localize(item.tabLabel) }}
        </v-tab>
      </v-tabs>
      <v-spacer/>
      <v-btn small color="success" :outlined="!isMounted || !isFormsFilled" :disabled="!isMounted || !isFormsFilled"
             class="mx-2" @click="save">{{ $localize('create_an_application') }}
      </v-btn>
      <v-btn small color="success" @click="dialog = true"> {{ $localize('application_information') }}</v-btn>
    </v-toolbar>
    <v-card-text class="tab-card-actions pa-0">
      <v-tabs-items v-model="tab" class="tab-table-1">
        <v-tab-item eager style="width: 80%" class="mb-4 py-3">
          <QualityRating :items="items" class="elevation-6"/>
        </v-tab-item>
        <v-tab-item eager style="width: 80%" class="mb-4 py-3">
          <PreDefault :items="itemsPreDefault" class="elevation-6"/>
        </v-tab-item>
        <v-tab-item eager style="width: 80%" class="mb-4 py-3">
          <QuantityRating :item="itemQuantity" :isMounted="isMounted" class="elevation-6"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <!--    <v-card-actions v-if="isMounted && isFormsFilled" class="justify-center">-->
    <!--      <v-btn small color="success" @click="save" >{{$localize('create_an_application')}}</v-btn>-->
    <!--    </v-card-actions>-->
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        scrollable
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            class="toolbar-with-tabs"
            color="success"
        >
          <v-toolbar-title>{{ $localize('application_information') }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar dense class="toolbar-with-tabs elevation-0" style="flex: none;">
          <v-tabs v-model="dialogTab"
                  color="success"
                  style="position:sticky; top: 0; z-index: 1;"
                  show-arrows
          >
            <v-tab>{{ $localize('questionnaire') }}</v-tab>
            <v-tab>{{ $localize('balance') }}</v-tab>
            <v-tab>{{ $localize('PROFIT') }}</v-tab>
            <v-tab>{{ $localize('models') }}</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-card-text>
          <v-tabs-items v-model="dialogTab" class="pa-0 ma-0 tab-table-2">
            <v-tab-item class="pa-8 pt-4" style="width: 50%" eager>
              <Questionnaire :item="request" class="elevation-14"/>
            </v-tab-item>
            <v-tab-item class="pa-8 pt-4" eager>
              <Balance :balanceProp="balance" class="elevation-14"/>
            </v-tab-item>
            <v-tab-item class="pa-8 pt-4" eager>
              <Profit :profitProp="profit" class="elevation-14"/>
            </v-tab-item>
            <v-tab-item class="pa-8 pt-4" eager>
              <Models :itemsTaffler="itemsTaffler"
                      :itemsChesser="itemsChesser"
                      :itemsIgea="itemsIgea"
                      class="elevation-14"/>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <RequestCreditRatingModal :dialog="creditRatingDialog"
                              :request="request"
                              :itemProp="creditRatingItem"
                              @closeCreditRatingModal="closeCreditRatingModal"
    />
  </v-card>
</template>

<script>
import QualityRating from "./components/QualityRating";
import PreDefault from "./components/PreDefault";
import QuantityRating from "./components/QuantityRating";

import Questionnaire from "./components/requestDetailInformation/Questionnaire";
import Balance from "./components/requestDetailInformation/Balance";
import Profit from "./components/requestDetailInformation/Profit";
import Models from "./components/requestDetailInformation/Models";

import RequestCreditRatingModal from "../requestsCreditRatingModal/index"

export default {
  name: "RequestProcessingData",
  components: {
    QualityRating,
    QuantityRating,
    PreDefault,
    Questionnaire,
    Balance,
    Profit,
    Models,
    RequestCreditRatingModal
  },

  data: () => ({
    items: [],
    itemsPreDefault: [],
    itemQuantity: undefined,

    requestUid: undefined,
    request: undefined,
    balance: {
      requestUid: undefined,
      headers: [],
      rows: []
    },
    balanceCopy: undefined,
    profit: {
      requestUid: undefined,
      headers: [],
      rows: []
    },
    profitCopy: undefined,
    dictItemsOrder: [
      'Выручка',
      'Себестоимость продаж',
      'Валовая прибыль (убыток)',
      'Административные расходы',
      'в т.ч. амортизация',
      'Расходы по реализации',
      'Прибыль (убыток) от продаж',
      'Проценты к получению',
      'Проценты к уплате',
      'Прочие доходы',
      'Прочие расходы',
      'Прибыль (убыток) до налогообложения',
      'Текущий налог на прибыль',
      'Прочее',
      'Чистая прибыль (убыток)'
    ],
    itemsTaffler: undefined,
    itemsChesser: undefined,
    itemsIgea: undefined,
    tab: undefined,
    dialogTab: undefined,
    tabItems: [
      {index: 1, tabLabel: "quality_rating", actionClass: "quality"},
      {index: 2, tabLabel: "predefault_rating", actionClass: "predefault"},
      {index: 3, tabLabel: "quantity_rating", actionClass: "quantity"},
    ],
    isMounted: false,

    loading: false,
    dialog: false,
    creditRatingDialog: false,
    creditRatingItem: undefined,
  }),

  computed: {
    isFormsFilled() {
      let isFilled = true
      if (this.loading) isFilled = false
      if (!this.items || !this.itemsPreDefault) isFilled = false
      this.items.forEach(el => {
        if (el.items && el.items !== 0) {
          el.items.forEach(item => {
            if (!item.ratingUid) isFilled = false
          })
        }
      })
      if (isFilled) {
        this.itemsPreDefault.forEach(el => {
          if (el.items && el.items !== 0) {
            el.items.forEach(item => {
              if (!item.ratingUid) isFilled = false
            })
          }
        })
      }
      if (isFilled && this.itemQuantity) {
        this.itemQuantity.list.forEach(el => {
          if ((!el.modelValue && el.modelValue !== 0) || (!el.risk && el.risk !== 0)) isFilled = false
        })
      }
      return isFilled
    }
  },

  watch: {
    loading(val) {
      if (val) this.$appUtils.setOverlay(true)
      else this.$appUtils.setOverlay(false)
    }
  },

  async mounted() {
    if (this.$route.params.uid && this.$testRegex('uid', this.$route.params.uid)) {
      this.requestUid = this.$route.params.uid;
    } else this.$router.push('/admin/requests-processing')
    await this.init()
    this.isMounted = true
  },

  methods: {
    async init() {
      this.loading = true
      const res = await this.$fetch.get('api/quality/get-list?requestUid=' + this.requestUid)
      if (res) this.items = res
      const resPreDefault = await this.$fetch.get('api/pre-default/get-list?requestUid=' + this.requestUid)
      if (resPreDefault) this.itemsPreDefault = resPreDefault
      let resQuantity = await this.$fetch.get('api/quantity/get?requestUid=' + this.requestUid)
      if (resQuantity) {
        resQuantity.list.forEach(l => {
          l.modelValue = l.modelValues[l.modelValues.length - 1].value
          l.risk = l.modelValues[l.modelValues.length - 1].risk
          l.grade = Number(l.value) * Number(l.risk)
          l.grade = l.grade.toFixed(3)
        })
        this.itemQuantity = resQuantity
      }
      await this.fetchRequestDetail()
      const resTuffler = await this.$fetch.get('api/request/tuffler/get?requestUid=' + this.requestUid)
      if (resTuffler) this.itemsTaffler = resTuffler
      const resChesser = await this.$fetch.get('api/request/chesser/get?requestUid=' + this.requestUid)
      if (resChesser) this.itemsChesser = resChesser
      const resIgea = await this.$fetch.get('api/request/igea/get?requestUid=' + this.requestUid)
      if (resIgea) this.itemsIgea = resIgea
      this.loading = false
    },

    async save() {
      this.loading = true
      const res = await this.$fetch.post('api/quality/save', this.items)
      const preDefaultRes = await this.$fetch.post('api/pre-default/save', this.itemsPreDefault)
      const quantitytRes = await this.$fetch.post('api/quantity/save', this.itemQuantity)
      if (res && preDefaultRes && quantitytRes) {
        const res = await this.$fetch.get('api/analysis/get?requestUid=' + this.requestUid)
        if (res) this.creditRatingItem = res
        this.$store.dispatch('setError', {show: true, type: 'success', message: 'processing_completed'})
        this.creditRatingDialog = true
        // this.$router.push('/admin/request/credit-rating/' + this.requestUid)
      } else {
        this.$store.dispatch('setError', {show: true, type: 'error', message: 'data_sending_error'})
      }
      this.loading = false
    },

    closeCreditRatingModal() {
      this.creditRatingDialog = false
      this.creditRatingItem = undefined
    },

    async fetchRequestDetail() {
      const resQuestionnarie = await this.$fetch.get('api/request/get?uid=' + this.requestUid)
      if (resQuestionnarie) {
        this.request = resQuestionnarie
        this.request.attracting = this.$localize(this.request.attracting)
        this.request.company.type = this.$localize(this.request.company.type)
        this.request.guarantee = this.request.guarantee ? this.$localize('yes') : this.$localize('no')
      }
      const resBalance = await this.$fetch.get('api/request/balance/get?requestUid=' + this.requestUid)
      if (resBalance) {
        this.balanceCopy = JSON.parse(JSON.stringify(resBalance))
        if (this.balanceCopy) this.balance.headers = this.balanceCopy.headers
        await this.initBalanceByType('ASSETS', resBalance)
        await this.initBalanceByType('PASSIVE', resBalance)
        this.calculateSum()
      }
      const resProfit = await this.$fetch.get('api/request/profit/get?requestUid=' + this.requestUid)
      if (resProfit) {
        this.profitCopy = JSON.parse(JSON.stringify(resProfit))
        if (this.profitCopy) this.profit.headers = this.profitCopy.headers
        await this.initProfit()
      }
    },

    initBalanceByType(type, balance) {
      if (balance && balance.headers && balance.rows && balance.headers.length >= 0 && balance.rows.length >= 0) {
        let dict = []
        balance.rows.forEach(b => {
          let parent = b.parent
          parent.items = []
          if (b.items) {
            b.items.forEach(bi => {
              parent.items.push(bi.dictionary)
            })
          }
          dict.push(parent)
        })
        if (dict && dict.length) {
          let dictByType = dict.filter(d => {
            return d.type === type && !d.deleted
          })
          if (dictByType && dictByType.length) {
            //dictName
            this.balance.rows.push({
              title: this.$localize(type),
              fullRow: true,
              class: 'dict-name'
            })
            //dict groups
            dictByType.forEach((d, idx) => {
              if (d && d.items && d.items.length) {
                let rowItem = {
                  title: d.title,
                  parent: d,
                  fullRow: true,
                  class: 'group-name',
                  items: []
                }
                d.items.forEach(dChild => {
                  if (dChild.deleted) return
                  let childRowItem = {dictionary: dChild, values: [], fullRow: false, class: ''}
                  this.balance.headers.forEach(h => {
                    const oldValue = this.balanceCopy && this.balanceCopy.rows && this.balanceCopy.rows.length ? this.searchInBalanceCopy(dChild.uid, h.date) : null
                    let childColItem = {date: h.date, headerUid: h.uid, value: 0}
                    childRowItem.values.push(oldValue ? oldValue : childColItem)
                  })
                  rowItem.items.push(childRowItem)
                })
                this.balance.rows.push(rowItem)
                let total = {
                  title: this.$localize('section_total'),
                  fullRow: false,
                  class: 'total-line',
                  type: 'total',
                  dictUid: d.uid,
                  dictType: type,
                  values: [],
                  idx: idx
                }
                this.balance.headers.forEach(h => {
                  total.values.push({date: h.date, headerUid: h.uid, value: 0})
                })
                this.balance.rows.push(total)
              }
            })
          }
          let groupTotal = {
            title: type === 'ASSETS' ? this.$localize('assets_total') : this.$localize('passive_total'),
            fullRow: false,
            class: 'group-total-line',
            type: 'groupTotal',
            dictType: type,
            values: []
          }
          this.balance.headers.forEach(h => {
            groupTotal.values.push({date: h.date, headerUid: h.uid, value: 0})
          })
          this.balance.rows.push(groupTotal)
        }
      }
    },

    //profit
    initProfit() {
      if (this.profitCopy && this.profitCopy.rows && this.profitCopy.rows.length) {
        let dict = []
        this.profitCopy.rows.forEach(b => {
          dict.push(b.dictionary)
        })
        if (dict && dict.length) {
          //sort dict
          dict = dict.map(e => {
            e.sortBy = this.dictItemsOrder.indexOf(e.title) !== -1 ? this.dictItemsOrder.indexOf(e.title) : dict.length;
            return e;
          });
          dict.sort((a, b) => a.sortBy - b.sortBy);
          let last = "Итого";
          dict.sort(function (x, y) {
            return x.title == last ? 1 : y.title == last ? -1 : 0;
          });

          let dictByType = dict.filter(d => {
            return d.type === 'PROFIT' && !d.deleted
          })
          if (dictByType && dictByType.length) {
            //dictName
            this.profit.rows.push({
              title: this.$localize('PROFIT'),
              fullRow: true,
              class: 'dict-name'
            })
            //dict groups
            dictByType.forEach(d => {
              let childRowItem = {dictionary: d, values: [], fullRow: false, class: ''}
              this.profit.headers.forEach(h => {
                const oldValue = this.profitCopy && this.profitCopy.rows && this.profitCopy.rows.length ? this.searchInProfitCopy(d.uid, h.date) : null
                let childColItem = {date: h.date, headerUid: h.uid, value: 0}
                childRowItem.values.push(oldValue ? oldValue : childColItem)
              })
              this.profit.rows.push(childRowItem)
            })
          }
        }
      }
    },

    searchInBalanceCopy(dictUid, date) {
      let value = null
      if (!this.balanceCopy || !this.balanceCopy.rows) return value
      for (const el of this.balanceCopy.rows) {
        if (el.items && el.items.length) {
          for (const i of el.items) {
            if (i.dictionary && i.dictionary.uid && i.dictionary.uid === dictUid) {
              if (i.values && i.values.length) {
                for (const v of i.values) {
                  if (v.date === date) {
                    value = v
                    break;
                  }
                }
              }
            }
            if (value) break;
          }
        }
        if (value) break;
      }
      return value
    },

    searchInProfitCopy(dictUid, date) {
      let value = null
      if (!this.profitCopy || !this.profitCopy.rows) return value
      for (const el of this.profitCopy.rows) {
        if (el.dictionary && el.dictionary.uid && el.dictionary.uid === dictUid) {
          if (el.values && el.values.length) {
            for (const v of el.values) {
              if (v.date === date) {
                value = v
                break;
              }
            }
            if (value) break;
          }
        }
        if (value) break;
      }
      return value
    },

    //sums
    calculateSum() {
      let dictTotalsSum = []
      if (this.balance && this.balance.rows && this.balance.rows.length) {
        this.balance.rows.forEach(r => {
          if (r.items && r.items.length) {
            dictTotalsSum.push({dictUid: r.parent.uid, dictType: r.parent.type, totals: this.getSum(r)})
          }
        })
        if (dictTotalsSum && dictTotalsSum.length) {
          dictTotalsSum.forEach(el => {
            let total = this.balance.rows.find(r => (r.type === 'total' && r.dictUid === el.dictUid))
            el.totals.forEach((e, idx) => {
              total.values[idx].value = e
            })
          })
          this.totalByDictGroup('ASSETS', dictTotalsSum)
          this.totalByDictGroup('PASSIVE', dictTotalsSum)
        }
      }
    },

    getSum(item) {
      let totals = []
      this.balance.headers.forEach((headEl, innerIdx) => {
        let sum = 0;
        item.items.forEach(el => {
          el.values.forEach(value => {
            if (headEl.date === value.date) {
              if (value.value) sum += Number(value.value)
            }
          })
        })
        totals[innerIdx] = sum
      })
      return totals
    },

    totalByDictGroup(type, dictTotalsSum) {
      let groupTotal = []
      groupTotal = this.balance.rows.find(r => r.type === 'groupTotal' && r.dictType === type)
      let groupTotalSum = dictTotalsSum.filter(d => d.dictType === type)
      if (groupTotal && groupTotal.values) {
        for (let i = 0; i < groupTotal.values.length; i++) {
          let sum = 0
          for (let j = 0; j < groupTotalSum.length; j++) {
            sum += groupTotalSum[j].totals[i]
          }
          groupTotal.values[i].value = sum
        }
      }
    },
  }
}
</script>
