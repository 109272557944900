<template>
  <v-simple-table v-if="isMounted"
                  fixed-header
                  dense
                  mobile-breakpoint="0"
                  style="height: 100%;"
                  class="table-with-borders"

  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center text-subtitle-1">{{ $localize('nomer_p_p') }}</th>
        <th class="text-center text-subtitle-1">{{ $localize('financial_condition_of_the_borrower') }}</th>
        <th class="text-center text-subtitle-1">
          {{ $localize('criteria_signs_assessment_of_group_and_intragroup_indicators') }}
        </th>
        <th class="text-center text-subtitle-1">{{ $localize('eights') }}</th>
        <th class="text-center text-subtitle-1">{{ $localize('value_of_indicators') }}</th>
        <th class="text-center text-subtitle-1">{{ $localize('risk_assessment_class') }}</th>
        <th class="text-center text-subtitle-1">{{ $localize('eighted_score') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="group-name">
        <td></td>
        <td>
          {{ item.key }}
        </td>
        <td>
          {{ item.title }}
        </td>
        <td>
          {{ item.value }}
        </td>
        <td></td>
        <td></td>
        <td>{{ gradeSum }}</td>
      </tr>
      <template v-for="(childItem, idx) in item.list">
        <tr :key="idx">
          <td></td>
          <td>
            {{ childItem.key }}
          </td>
          <td>
            {{ childItem.title }}
          </td>
          <td>
            {{ childItem.value }}
          </td>
          <td>
            <v-text-field
                v-model="childItem.modelValue"
                outlined
                readonly
                dense
                class="mt-5 input-number-without-spin-button"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
                v-model="childItem.risk"
                readonly
                outlined
                dense
                class="mt-5 input-number-without-spin-button"
            >
            </v-text-field>
          </td>
          <td>{{ childItem.grade }}</td>
        </tr>
      </template>
      </tbody>
      <tr>
        <td colspan="7">
          <v-divider/>
        </td>
      </tr>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "QuantityRating",
  props: ['item', 'isMounted'],
  data() {
    return {
      rules: {
        required: value => !!value || this.$localize('mandatory_field')
      },
    }
  },
  computed: {
    gradeSum() {
      if (this.item && this.item.list && this.item.list.length) {
        let sum = 0
        this.item.list.forEach(l => sum += Number(l.grade))
        sum = sum.toFixed(3)
        this.setGradeSum(sum)
        return sum
      }
      return ''
    }
  },

  methods: {
    setGradeSum(sum) {
      this.item.totalGrade = sum
    }
  }
}
</script>
