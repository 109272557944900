<template>
  <v-card>
    <v-card-title class="pa-2">Модель Таффлера</v-card-title>
    <v-card-text style="display: contents">
      <!--        v-if="itemsTaffler.rows.length > 0"-->
      <v-simple-table v-if="isItemsTaffler"
                      fixed-header
                      dense
                      mobile-breakpoint="0"
                      style="height: 100%"
                      class="table-with-borders"
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">
              Наименование показателя
            </th>
            <th v-for="( title, headerIdx ) in itemsTaffler.items[0].sums"
                :key="headerIdx"
                class="text-center"
            >
              <span style="width: auto">{{ title.date }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="( item, rowIdx) in itemsTaffler.items">
            <tr :key="rowIdx">
              <td>{{item.title}}</td>
              <td v-for="(colItemValue, colItemValueIdx) in itemsTaffler.items[0].sums"
                  :key="'r ' + rowIdx + ' v ' + colItemValueIdx"
              >
                {{itemsTaffler.items[rowIdx].sums[colItemValueIdx] ? itemsTaffler.items[rowIdx].sums[colItemValueIdx].sum : ''}}
              </td>
            </tr>
          </template>
          <tr>
            <td>Z = 0,53Х1+0,13Х2+0,18Х3+0,16Х4</td>
            <td v-for="(sum, colItemValueIdx) in itemsTaffler.sum"
                :key="'t' + colItemValueIdx"
            >
              {{sum}}
            </td>
          </tr>
          <tr>
            <td>Вероятность банкротства компании</td>
            <td v-for="(sum, colItemValueIdx) in itemsTaffler.sum"
                :key="'tb' + colItemValueIdx"
            >
              {{tafflerProbabilityOfBankruptcy(sum)}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-title class="pa-2">Модель Чессера</v-card-title>
      <v-simple-table v-if="isItemsChesser"
                      fixed-header
                      dense
                      mobile-breakpoint="0"
                      style="height: 100%"
                      class="table-with-borders"
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">
              Наименование показателя
            </th>
            <th v-for="( title, headerIdx ) in itemsChesser.items[0].sums"
                :key="headerIdx"
                class="text-center"
            >
              <span style="width: auto">{{ title.date }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="( item, rowIdx) in itemsChesser.items">
            <tr :key="rowIdx">
              <td>{{item.title}}</td>
              <td v-for="(colItemValue, colItemValueIdx) in itemsChesser.items[0].sums"
                  :key="'r ' + rowIdx + ' v ' + colItemValueIdx"
                  style="width: 150px"
              >
                {{itemsChesser.items[rowIdx].sums[colItemValueIdx] ? itemsChesser.items[rowIdx].sums[colItemValueIdx].sum : ''}}
              </td>
            </tr>
          </template>
          <tr>
            <td>Y= –2,0434–5,24K1+0,0053K2–6,6507K3+4,4009K4–0,07915K5+0,102K6</td>
            <td v-for="(sum, colItemValueIdx) in itemsChesser.sum"
                :key="'c ' + colItemValueIdx"
            >
              {{sum}}
            </td>
          </tr>
          <tr>
            <td>Z = 1/[1 + e-Y]</td>
            <td v-for="(sum, colItemValueIdx) in itemsChesser.sum"
                :key="'c ' + colItemValueIdx"
            >
              {{chesserZ(sum)}}
            </td>
          </tr>
          <tr>
            <td>Вероятность банкротства компании</td>
            <td v-for="(sum, colItemValueIdx) in itemsChesser.sum"
                :key="'c ' + colItemValueIdx"
            >
              {{chesserProbabilityOfBankruptcy(sum)}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-title class="pa-2">Модель ИГЭА</v-card-title>
      <v-simple-table v-if="isItemsIgea"
                      fixed-header
                      dense
                      mobile-breakpoint="0"
                      style="height: 100%"
                      class="table-with-borders"
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center">
              Наименование показателя
            </th>
            <th v-for="( title, headerIdx ) in itemsIgea.items[0].sums"
                :key="headerIdx"
                class="text-center"
            >
              <span style="width: auto">{{ title.date }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-for="( item, rowIdx) in itemsIgea.items">
            <tr :key="rowIdx">
              <td>{{item.title}}</td>
              <td v-for="(colItemValue, colItemValueIdx) in itemsIgea.items[0].sums"
                  :key="'r ' + rowIdx + ' v ' + colItemValueIdx"
                  style="width: 150px"
              >
                {{itemsIgea.items[rowIdx].sums[colItemValueIdx] ? itemsIgea.items[rowIdx].sums[colItemValueIdx].sum : ''}}
              </td>
            </tr>
          </template>
          <tr>
            <td>R-модель</td>
            <td v-for="(sum, colItemValueIdx) in itemsIgea.sum"
                :key="'i ' + colItemValueIdx"
            >
              {{sum}}
            </td>
          </tr>
          <tr>
            <td>Вероятность банкротства компании</td>
            <td v-for="(sum, colItemValueIdx) in itemsIgea.sum"
                :key="'i ' + colItemValueIdx"
            >
              {{igeaProbabilityOfBankruptcy(sum)}}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name:"Models",
  props:['itemsTaffler', 'itemsChesser', 'itemsIgea'],
  computed:{
    isItemsTaffler(){
      return this.itemsTaffler && this.itemsTaffler.items && this.itemsTaffler.items[0] && this.itemsTaffler.items[0].sums && this.itemsTaffler.items[0].sums.length
    },
    isItemsChesser(){
      return this.itemsChesser && this.itemsChesser.items && this.itemsChesser.items[0] && this.itemsChesser.items[0].sums && this.itemsChesser.items[0].sums.length
    },
    isItemsIgea(){
      return this.itemsIgea && this.itemsIgea.items && this.itemsIgea.items[0] && this.itemsIgea.items[0].sums && this.itemsIgea.items[0].sums.length
    }
  },

  methods:{
    tafflerProbabilityOfBankruptcy(sum){
      if(sum){
        if(Number(sum) < 0.3 && Number(sum) > 0.2) return 1
        else if(Number(sum) < 0.2) return 4
        return 1
      }else return ''
    },

    chesserZ(sum){
      if(sum) {
        let z = 1/(1 + 2.71828 ** -Number(sum))
        return z.toFixed(2)
      }
      else return ''
    },

    chesserProbabilityOfBankruptcy(sum){
      if(sum){
        let z = this.chesserZ(sum)
        return z > 0.5 ? 4 : 1
      }else return ''
    },

    igeaProbabilityOfBankruptcy(sum){
      if(sum){
        if(sum < 0 ) return 4
        else if(sum <= 0.32) return 3
        else if(sum <= 0.42) return 2
        return 1
      }else return ''
    }
  }
}
</script>
