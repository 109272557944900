<template>
  <!--  max-width="500px"-->
  <v-dialog v-model="dialog" scrollable persistent>
    <v-card v-if="item && request" class="view-wrapper pb-12">
      <v-card-title class="pa-4 ml-4">
        {{ $localize('borrower_credit_rating') }}
        <v-spacer/>
        <v-btn icon
               @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="!item">
        <v-row class="justify-center"><span class="subtitle-1">{{$localize('there_is_nothing_here_yet')}}</span></v-row>
      </v-card-text>
<!--      <v-card-text v-else class="align-center h-full">-->
      <v-card-text v-else>
<!--        <v-card v-if="isMounted">-->
          <v-row class="justify-center title no-gutters">{{request.company.title}}</v-row>
          <v-divider/>
          <div class="d-flex justify-start align-center mt-2 pb-0 mb-0" style="flex-direction: column">
            <div class="two-columns-grid align-start justify-center">
              <div class="two-columns-grid-place-items-start ml-12">
                <span class="title">Отрасль: </span>
                <span class="subtitle-1">{{request.company && request.company.activity ? request.company.activity.title : ''}}</span>
                <span class="title">Дата образования: </span>
                <span class="subtitle-1">{{$dateFormat(request.date)}}</span>
                <span class="title">Учредитель: </span>
                <span class="subtitle-1">{{request.company.fullNameDirector}}</span>
                <span class="title">Руководитель: </span>
                <span class="subtitle-1">{{request.company.fullNameMainDirector}}</span>
                <span class="title">Запрашиваемая сумма: </span>
                <span class="subtitle-1">{{$moneyFormat(request.amount)}}</span>
                <span class="title">Цель инвестиции: </span>
                <span class="subtitle-1">{{$localize(request.attracting)}}</span>
              </div>
              <v-simple-table
                  fixed-header
                  dense
                  mobile-breakpoint="0"
                  style="height: 100%"
                  class="table-with-borders"
              >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-center text-subtitle-1">Рейтинг</th>
                    <th class="text-center text-subtitle-1">Вес</th>
                    <th class="text-center text-subtitle-1">Балл</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr>
                      <td>Количественный рейтинг</td>
                      <td>50%</td>
                      <td>{{item.quantityValue}}</td>
                    </tr>
                    <tr>
                      <td>Качественный рейтинг</td>
                      <td>50%</td>
                      <td>{{item.qualityValue}}</td>
                    </tr>
                    <tr>
                      <td>Финальный рейтинг</td>
                      <td></td>
                      <td>{{ finalRating }}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>Near Default Signal Rating (NDS)</td>
                      <td style="background-color: lawngreen">{{item.preDefaultValue}}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Финальный рейтинг после NDS</td>
                      <td>{{finalRatingaAfterNDS}}</td>
                      <td>{{finalRatingaAfterNDSLetter}}</td>
                    </tr>
                  </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <v-row style="width: 100%" class="ma-0 pa-0 pt-8">
              <v-col cols="12" md="3" class="ma-0 pa-0"></v-col>
              <v-col cols="12" md="6" class="ma-0 pa-0">
                <v-textarea
                    v-model="item.description"
                    dense
                    outlined
                    label="Мнение эксперта"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="4" class="ma-0 pa-0"></v-col>
            </v-row>
          </div>
<!--        </v-card>-->
      </v-card-text>
      <v-card-actions class="pt-0 justify-center">
        <div class="two-columns-grid">
          <v-text-field v-model="item.date"
                        :rules="[rules.required]"
                        :label= "$localize('analysis_date')"
                        type="date"
                        class="mt-6"
                        outlined
                        dense
          ></v-text-field>
          <div>
            <v-btn :disabled="!item.description || !item.date" outlined color="success" class="mx-4" @click="save">Сохранить</v-btn>
            <v-btn :disabled="!item.description || !item.date" outlined color="success" @click="close">Отправить заявку на КК</v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog:{
      type: Boolean,
      default: false,
    },
    request:{
      type: Object,
      default: () => {},
    },
    itemProp:{
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      item: this.itemProp,
      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
      },
      isMounted: false,
    }
  },

  computed: {
    finalRating(){
      return this.item.quantityValue/2 +  this.item.qualityValue/2
    },

    finalRatingaAfterNDS(){
      return this.finalRating + this.item.preDefaultValue
    },

    finalRatingaAfterNDSLetter(){
      if(this.finalRatingaAfterNDS === 0) return ''
      else if(this.finalRatingaAfterNDS < 1.5) return 'A'
      else if(this.finalRatingaAfterNDS < 2) return 'A -'
      else if(this.finalRatingaAfterNDS < 2.5) return 'B'
      else if(this.finalRatingaAfterNDS < 3) return 'B -'
      else if(this.finalRatingaAfterNDS < 3.5) return 'C'
      else if(this.finalRatingaAfterNDS < 4) return 'C -'
      else if(this.finalRatingaAfterNDS < 4.5) return 'D'
      return ''
    }
  },

  watch: {
    itemProp: {
      handler(n, o) {
        if(n !== o) {
          this.item = n
        }
      },
    },
  },

  methods:{
    async save(){
      this.item.finalRating = this.finalRating
      this.item.finalRatingNds = this.finalRatingaAfterNDS
      this.item.rating = this.finalRatingaAfterNDSLetter
      const res = await this.$fetch.post('api/analysis/save', this.item)
      if(res) {
        this.$store.dispatch('setError',{ show: true, type: 'success', message: this.$localize('success')})
        this.close()
      }else this.$store.dispatch('setError',{ show: true, type: 'error', message: this.$localize('data_sending_error')})
    },

    close(){
      this.$emit('closeCreditRatingModal')
    }
  }
}
</script>


